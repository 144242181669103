import React, { useEffect, useState } from 'react'
import ProductCard from './ProductCard';
import { certificate1, certificate2, certificate3, certificate4, certificate5, certificate6, huwasan, news1, news2, news3, productBg, virus1, virus2, virus3, virus4, virus5, whyus, whyus2 } from '../../../assets/images';
import { useTranslation } from 'react-i18next';
import axios from 'axios';


const News = () => {

   const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.sossecurity.az/sossecurity/news.php`,
        { withCredentials: false }
      );
      const filteredProducts = response.data.data.slice(0, 3); // İlk 3 öğeyi al
      setBlogs(filteredProducts);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };
  

  const { t, i18n } = useTranslation();


  if (isLoading) {
    return <div>Loading...</div>; // Show loading indicator while data is being fetched
  }


  return (
    <div className='bg-[black] text-[white] md:h-[680px] flex justify-center items-center pb-10 md:pb-0'>
    <div className='md:max-w-container max-w-containerSm mx-auto md:h-[520px] flex flex-col justify-between'>
     <div className='text-center'>
     <p className='text-[32px]'>{t("Xəbərlər")}</p>
     <p className='text-[18px] text-[#DBDBDB] italic'>{t("Şirkətimiz barədə xəbərlər")}</p>
     </div>
     <div className='grid md:grid-cols-3 grid-cols-1 md:gap-10 gap-4'>
          {blogs.map((val, index) => {
            const { id, image1, title, text } = val;
            // Limit the text to 100 characters
            const truncatedTitle = t(title).length > 60 ? `${t(title).slice(0, 60)}...` : t(title);
            const truncatedText = t(text).length > 130 ? `${t(text).slice(0, 130)}...` : t(text);

            return (
              <a href={`/news/${id}`} key={index}>
                <div className='rounded-[20px] pt-1'>
                  <div className='mt-3 mb-5 ml-4 mr-4'>
                    <img className='rounded-lg h-[273px]' src={`https://www.sossecurity.az/sossecurity/${image1}`} alt={title} />
                    <div className='text mt-5'>
                      <h4 className='text-white text-base md:text-xl font-medium mb-2'>{t(truncatedTitle)}</h4>
                    </div>
                  </div>
                </div>
              </a>
            ); 
          })}
        </div>
   <a className='mx-auto' href='/news'>
   <div className='flex mx-auto'>
     <p className='text-[#DDB14C] mr-2'> {t("Daha çox")} </p>
     <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.7146 5.46967C16.0563 5.17678 16.6103 5.17678 16.952 5.46967L23.952 11.4697C24.2938 11.7626 24.2938 12.2374 23.952 12.5303L16.952 18.5303C16.6103 18.8232 16.0563 18.8232 15.7146 18.5303C15.3729 18.2374 15.3729 17.7626 15.7146 17.4697L21.2209 12.75H4.66667C4.18342 12.75 3.79167 12.4142 3.79167 12C3.79167 11.5858 4.18342 11.25 4.66667 11.25H21.2209L15.7146 6.53033C15.3729 6.23744 15.3729 5.76256 15.7146 5.46967Z" fill="#DDB14C"/>
</svg>
    </div>
   </a>


    </div>
  </div>
  )
}

export default News