import React from 'react';
import { logo, logoFooter } from '../../assets/images';
import { useTranslation } from 'react-i18next';

const Footer = () => {

  const { t, i18n } = useTranslation();

  return (
    <footer className="py-8 md:h-[420px] bg-[#181818] text-[#C5C5C5] flex justify-center item-center">
      <div className='md:max-w-container max-w-containerSm md:h-[275px]'>
      <div className="md:max-w-container max-w-containerSm mx-auto md:flex justify-between gap-8 mb-3">
        <div className='md:w-[30%]'>
          <h3 className="text-xl font-semibold mb-2"><img className='h-[76px] w-[59px] mx-auto' src={logoFooter}></img></h3>
          <p className='text-[14px] mt-4'>{t("'Security Operation Services' MMC Azərbaycan Respublikası Daxili İşlər Nazirliyinin 24 iyul 2020-ci il tarixli, 108 nömrəli Lisenziyası əsasında 2020-ci ilin noyabr ayının 1 dən 'Qeyri-dövlət (özəl) mühafizə fəaliyyəti haqqında Azərbaycan Respublikasının Qanununa müvafiq olaraq mühafizə xidmətini həyata keçirən özəl mühafizə şirkətidir.")}</p>
        </div>
        <div className='md:w-[50%] md:flex'>
        <div className='mr-[100px]'> 
          <h4 className="text-lg font-semibold mb-2 mt-4 md:mt-0">{t("Keçidlər")}</h4>
          <ul className="space-y-3 mt-4">
  <li><a href="/" className="hover:underline">{t("Ana səhifə")}</a></li>
  <li><a href="/services" className="hover:underline">{t("Xidmətlər")}</a></li>
  <li><a href="/about" className="hover:underline">{t("Haqqımızda")}</a></li>
  <li><a href="/news" className="hover:underline">{t("Xəbərlər")}</a></li>
  <li><a href="/media" className="hover:underline">{t("Media")}</a></li>
  <li><a href="/contact" className="hover:underline">{t("Əlaqə")}</a></li>
</ul>

        </div>
        <div>
          <h4 className="text-lg font-semibold mb-4 mt-4 md:mt-0">{t("Ünvan")}</h4>
          <ul>
          <div className=' h-1/4 flex mb-2'>
          <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C7.58172 2 4 6.00258 4 10.5C4 14.9622 6.55332 19.8124 10.5371 21.6744C11.4657 22.1085 12.5343 22.1085 13.4629 21.6744C17.4467 19.8124 20 14.9622 20 10.5C20 6.00258 16.4183 2 12 2ZM12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12Z" fill="#C5C5C5"/>
</svg>
  <p className='ml-3'>{t("Sabunçu Rayonu, Balaxanı qəsəbəsi, Ramiz Mirişli 102")}</p>
</div>
<h4 className="text-lg font-semibold mb-2 mt-4 md:mt-0">{t("Əlaqə nömrəsi")}</h4>
<div className=' h-1/4 flex mb-2'>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.03759 1.31617L6.6866 2.4791C7.2723 3.52858 7.03718 4.90532 6.11471 5.8278C6.11471 5.8278 6.11471 5.8278 6.11471 5.8278C6.11459 5.82792 4.99588 6.94685 7.02451 8.97548C9.05248 11.0035 10.1714 9.8861 10.1722 9.88529C10.1722 9.88527 10.1722 9.88528 10.1722 9.88525C11.0947 8.96281 12.4714 8.7277 13.5209 9.31339L14.6838 9.96241C16.2686 10.8468 16.4557 13.0692 15.0628 14.4622C14.2258 15.2992 13.2004 15.9505 12.0669 15.9934C10.1588 16.0658 6.91828 15.5829 3.6677 12.3323C0.417128 9.08172 -0.0657854 5.84122 0.00655165 3.93309C0.0495219 2.7996 0.700803 1.77423 1.53781 0.937232C2.93076 -0.455718 5.15317 -0.268563 6.03759 1.31617Z" fill="#C5C5C5"/>
</svg>
  <div>
  <p className='ml-3'>+994 77 280 01 03</p>
  <p className='ml-3'>+994 77 280 01 06</p>
  </div>
</div>
<h4 className="text-lg font-semibold mb-2 mt-4 md:mt-0">{t("E-mail")}</h4>
<div className=' h-1/4 flex mb-2 '>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.17157 5.17157C2 6.34315 2 8.22876 2 12C2 15.7712 2 17.6569 3.17157 18.8284C4.34315 20 6.22876 20 10 20H14C17.7712 20 19.6569 20 20.8284 18.8284C22 17.6569 22 15.7712 22 12C22 8.22876 22 6.34315 20.8284 5.17157C19.6569 4 17.7712 4 14 4H10C6.22876 4 4.34315 4 3.17157 5.17157ZM18.5762 7.51986C18.8413 7.83807 18.7983 8.31099 18.4801 8.57617L16.2837 10.4066C15.3973 11.1452 14.6789 11.7439 14.0448 12.1517C13.3843 12.5765 12.7411 12.8449 12 12.8449C11.2589 12.8449 10.6157 12.5765 9.95518 12.1517C9.32112 11.7439 8.60271 11.1452 7.71636 10.4066L5.51986 8.57617C5.20165 8.31099 5.15866 7.83807 5.42383 7.51986C5.68901 7.20165 6.16193 7.15866 6.48014 7.42383L8.63903 9.22291C9.57199 10.0004 10.2197 10.5384 10.7666 10.8901C11.2959 11.2306 11.6549 11.3449 12 11.3449C12.3451 11.3449 12.7041 11.2306 13.2334 10.8901C13.7803 10.5384 14.428 10.0004 15.361 9.22291L17.5199 7.42383C17.8381 7.15866 18.311 7.20165 18.5762 7.51986Z" fill="#C5C5C5"/>
</svg>
  <div>
  <p className='ml-3'>info@sossecurity.az</p>
  </div>
</div>
<div className=' h-1/4 flex mb-2'>
<div className='rounded-full bg-[#4B4B4B] h-[24px] w-[24px] mr-[10px]'>
<svg width="24" height="24" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.0683 0H29.9317C13.4009 0 0 13.4009 0 29.9317V30.0683C0 46.5991 13.4009 60 29.9317 60H30.0683C46.5991 60 60 46.5991 60 30.0683V29.9317C60 13.4009 46.5991 0 30.0683 0Z" fill="#4B4B4B"/>
<path d="M39.2839 12.2068H20.7183C15.5892 12.2068 11.4165 16.3794 11.4165 21.5085V38.4934C11.4165 43.6225 15.5892 47.7952 20.7183 47.7952H39.2839C44.413 47.7952 48.5857 43.6225 48.5857 38.4934V21.5085C48.5857 16.3794 44.413 12.2068 39.2839 12.2068ZM14.6979 21.5085C14.6979 18.1893 17.3991 15.4881 20.7183 15.4881H39.2839C42.6031 15.4881 45.3043 18.1893 45.3043 21.5085V38.4934C45.3043 41.8126 42.6031 44.5138 39.2839 44.5138H20.7183C17.3991 44.5138 14.6979 41.8126 14.6979 38.4934V21.5085Z" fill="white"/>
<path d="M30.0011 38.6511C34.7707 38.6511 38.6533 34.7706 38.6533 29.9989C38.6533 25.2271 34.7728 21.3467 30.0011 21.3467C25.2293 21.3467 21.3489 25.2271 21.3489 29.9989C21.3489 34.7706 25.2293 38.6511 30.0011 38.6511ZM30.0011 24.6301C32.9629 24.6301 35.3719 27.0391 35.3719 30.001C35.3719 32.9628 32.9629 35.3718 30.0011 35.3718C27.0392 35.3718 24.6302 32.9628 24.6302 30.001C24.6302 27.0391 27.0392 24.6301 30.0011 24.6301Z" fill="white"/>
<path d="M39.4543 22.7445C40.7386 22.7445 41.7855 21.6997 41.7855 20.4133C41.7855 19.1268 40.7407 18.082 39.4543 18.082C38.1678 18.082 37.123 19.1268 37.123 20.4133C37.123 21.6997 38.1678 22.7445 39.4543 22.7445Z" fill="white"/>
</svg>

</div>

<div className='rounded-full bg-[#4B4B4B] h-[24px] w-[24px] mr-[10px]'>
<svg width="24" height="24" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M59.9958 30.001C59.9958 45.1529 48.7647 57.6793 34.1746 59.712C32.8104 59.9012 31.4147 60 29.9979 60C28.3625 60 26.7566 59.8697 25.1927 59.6174C10.9095 57.3177 0 44.9322 0 30.001C0 13.4324 13.4319 0 30 0C46.5681 0 60 13.4324 60 30.001H59.9958Z" fill="#4B4B4B"/>
<path d="M34.1746 24.0901V30.6255H42.259L40.9788 39.429H34.1746V59.7121C32.8104 59.9013 31.4146 60.0001 29.9979 60.0001C28.3625 60.0001 26.7566 59.8698 25.1927 59.6175V39.429H17.7368V30.6255H25.1927V22.6291C25.1927 17.6682 29.2138 13.6448 34.1767 13.6448V13.649C34.1914 13.649 34.204 13.6448 34.2187 13.6448H42.2611V21.2586H37.006C35.4442 21.2586 34.1767 22.5261 34.1767 24.088L34.1746 24.0901Z" fill="white"/>
</svg>
</div>
</div>
          </ul>
        </div>
        </div>
      </div>
      <hr></hr>
      <div className="flex justify-between text-center text-sm mx-auto mt-5">
       <p> SOS.az © {t("Bütün hüquqlar qorunur.")}</p>
       <p>{t("Sayt hazırlandı")}: Birsayt.az</p>
      </div>
      </div>
    </footer>
  );
};

export default Footer;
